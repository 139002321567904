import React, { useState } from 'react';
import './ChatInput.css';

interface ChatInputProps {
    onSendMessage: (message: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
    const [message, setMessage] = useState<string>('');

    const handleSend = () => {
        if (message.trim()) {
            onSendMessage(message);
            setMessage(''); // Clear the input after sending
        }
    };

    return (
        <div className="chat-input-container">
            <input
                type="text"
                className="chat-input-box"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') handleSend();
                }}
            />
            <button className="chat-send-button" onClick={handleSend}>
                Send
            </button>
        </div>
    );
};

export default ChatInput;