import React from 'react';
import PageHeader from '../components/PageHeader';
import './Account.css';
import Logout from '../components/Logout'; // Import the Logout component

const Account: React.FC = () => {
    return (
        <div className="account-page">
            <PageHeader title="Account Page" subtitle="Manage your account and billing" />
            <div className="account-content">
                <p>Account Page</p>
                <Logout /> {/* Add the Logout button */}
            </div>
        </div>
    );
};

export default Account;