import React from 'react';
import PageHeader from '../components/PageHeader';
import Login from '../components/Login';
import './Login.css';

const LoginPage: React.FC = () => {
    return (
        <div className="login-page">
            <PageHeader title="login" subtitle="Find the perfect wine" />
            <div className="login-content">
                <Login />
            </div>
        </div>
    );
};

export default LoginPage;