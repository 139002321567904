import React from 'react';
import ReactMarkdown from 'react-markdown';
import './ChatResponse.css';

interface ChatResponseProps {
    response: string;
    isUser: boolean;
    isLoading?: boolean;
}

const ChatResponse: React.FC<ChatResponseProps> = ({ response, isUser, isLoading }) => {
    return (
        <div className={`chat-response-container ${isUser ? 'user-message' : ''}`}>
            <div className={`chat-response-bubble ${isUser ? 'user-bubble' : ''}`}>
                {isLoading ? (
                    <div className="loading-spinner"></div>
                ) : (
                    <ReactMarkdown>{response}</ReactMarkdown>
                )}
            </div>
        </div>
    );
};

export default ChatResponse;