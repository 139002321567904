import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { googleLogout } from '@react-oauth/google';
import { auth } from '../config/firebase';
import { 
    GoogleAuthProvider, 
    signInWithCredential, 
    onAuthStateChanged,
    signOut,
    User
} from 'firebase/auth';

interface GoogleUser {
    id: string;
    email: string | null;
    name: string | null;
    picture?: string;
}

interface AuthContextType {
    isAuthenticated: boolean;
    user: GoogleUser | null;
    login: (token: string, refresh: string) => void;
    logout: () => void;
    getToken: () => Promise<string | null>;
    getRefreshToken: () => string | null;
    refreshToken: () => Promise<string | null>;
    loginWithGoogle: (credential: string) => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<GoogleUser | null>(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (firebaseUser: User | null) => {
            if (firebaseUser) {
                setIsAuthenticated(true);
                setUser({
                    id: firebaseUser.uid,
                    email: firebaseUser.email,
                    name: firebaseUser.displayName,
                    picture: firebaseUser.photoURL || undefined
                });
            } else {
                setIsAuthenticated(false);
                setUser(null);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    const login = useCallback((token: string, refresh: string): void => {
        localStorage.setItem('token', token);
        localStorage.setItem('refresh_token', refresh);
        setIsAuthenticated(true);
    }, []);

    const logout = useCallback(async (): Promise<void> => {
        try {
            await signOut(auth);
            googleLogout(); // Keep this if you still want to logout from Google OAuth
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            setIsAuthenticated(false);
            setUser(null);
        }
    }, []);

    const loginWithGoogle = useCallback(async (credential: string): Promise<void> => {
        try {
            console.log('Attempting Google login with credential...');
            const googleCredential = GoogleAuthProvider.credential(credential);
            
            const userCredential = await signInWithCredential(auth, googleCredential);
            const firebaseUser = userCredential.user;
            
            // Get tokens
            const accessToken = await firebaseUser.getIdToken();
            const refreshToken = firebaseUser.refreshToken;
            
            login(accessToken, refreshToken);
        } catch (error) {
            console.error('Google login failed:', error);
            throw error;
        }
    }, [login]);

    const getToken = useCallback(async (): Promise<string | null> => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            return currentUser.getIdToken();
        }
        return null;
    }, []);

    const getRefreshToken = useCallback((): string | null => {
        return auth.currentUser?.refreshToken || null;
    }, []);

    const refreshToken = useCallback(async (): Promise<string | null> => {
        try {
            const currentUser = auth.currentUser;
            if (currentUser) {
                return await currentUser.getIdToken(true); // Force refresh
            }
            return null;
        } catch (error) {
            console.error('Failed to refresh token:', error);
            await logout();
            return null;
        }
    }, [logout]);

    return (
        <AuthContext.Provider value={{ 
            isAuthenticated, 
            user, 
            login, 
            logout: () => { void logout(); }, 
            getToken, 
            getRefreshToken, 
            refreshToken, 
            loginWithGoogle 
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
};