import React from 'react';
import PageHeader from '../components/PageHeader';
import './Settings.css'; // Create a CSS file for styling

const Settings = () => {
    return (
        <div className="settings-page">
            <PageHeader title="Settings Page" subtitle="Manage your settings" />
            <div className="settings-content">
                Settings Page
            </div>
        </div>
    );
};

export default Settings;