import React, { createContext, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

const NavigateContext = createContext<ReturnType<typeof useNavigate> | null>(null);

export const NavigateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    return <NavigateContext.Provider value={navigate}>{children}</NavigateContext.Provider>;
};

export const useNavigateContext = () => {
    return useContext(NavigateContext);
};