import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import './Login.css';
import { GoogleLogin } from '@react-oauth/google';

interface TokenResponse {
    access: string;
    refresh: string;
}

/*
interface User {
    userId: number;
    displayName: string;
}*/

//const usersBaseURL = 'http://localhost:8000/api/users/';

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [step, setStep] = useState<number>(1);
    const { isAuthenticated, login, loginWithGoogle } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleNextClick = () => {
        if (username) {
            setStep(2);
        }
    };

   /* const fetchUser = async (userId: number): Promise<User> => {
        const response = await axios.get<User>(`${usersBaseURL}${userId}`);
        return response.data;
    };
*/
 /*   const hydrateUser = useCallback(async (userId: number): Promise<void> => {
        try {
            const user = await fetchUser(userId);
            const hydratedUser: User = {
                userId: user.userId,
                displayName: user.displayName
            };

        } catch (error) {
            console.error('Failed to fetch user:', error);
        }
    }, []);
*/
    const handleLogin = async () => {
        try {
            const response = await axios.post<TokenResponse>('http://localhost:8000/api/token/', {
                username,
                password,
            });
            login(response.data.access, response.data.refresh);
        } catch (error) {
            console.error('Login failed', error);
        }
    };

    const handleLoginClick = () => {
        void handleLogin();
    };

    const handleSignUpClick = () => {
        navigate('/signup');
    };

    return (
        <div className="login-container">
            {error && <div className="error-message">{error}</div>}
            {step === 1 ? (
                <div className="login-step">
                    <input
                        className="login-username"
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <button className="next-button" onClick={handleNextClick}>Next</button>
                    <div className="or-divider">or</div>
                    <GoogleLogin
                        onSuccess={(credentialResponse) => {
                            if (credentialResponse.credential) {
                                void loginWithGoogle(credentialResponse.credential)
                                    .catch((error) => {
                                        setError('Failed to login with Google. Please try again later.');
                                        console.error('Google Login Error:', error);
                                    });
                            }
                        }}
                        onError={() => {
                            setError('Google Login Failed. Please try again.');
                            console.error('Google Login Failed');
                        }}
                    />
                    <div className="or-divider">or</div>
                    <button className="signup-button" onClick={handleSignUpClick}>Sign up</button>
                </div>
            ) : (
                <div className="login-step">
                    <input
                        className="login-password"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button className="login-button" onClick={handleLoginClick}>Login</button>
                </div>
            )}
        </div>
    );
};

export default Login;