import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Workspace from './pages/Chat';
import Settings from './pages/Settings';
import Account from './pages/Account';
import Archive from './pages/Archive';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import './styles/borders.css';
import './styles/colors.css';
import './styles/fonts.css';
import Modal from 'react-modal';
import LoginPage from "./pages/Login";
import SignupPage from "./pages/Signup";
import { UserContentProvider } from './context/UserContentContext';
import { AuthProvider } from './context/AuthContext';
import { NavigateProvider } from './context/NavigateContext';
import Chat from './pages/Chat';
import { GoogleOAuthProvider } from '@react-oauth/google';

Modal.setAppElement('#root'); // Set the app element for react-modal

const App: React.FC = () => {

    return (
            <Router>
                <AuthProvider>
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                        <NavigateProvider>
                            <UserContentProvider>
                                <div className="app">
                                    <Sidebar />
                                    <div className="content">
                                        <Routes>
                                            <Route path="/" element={<ProtectedRoute element={<Workspace />} />} />
                                            <Route path="/chat" element={<ProtectedRoute element={<Chat />} />} />
                                            <Route path="/archive" element={<ProtectedRoute element={<Archive />} />} />
                                            <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />
                                            <Route path="/account" element={<ProtectedRoute element={<Account />} />} />
                                            <Route path="/login" element={<LoginPage />} />
                                            <Route path="/signup" element={<SignupPage />} />
                                        </Routes>
                                    </div>
                                </div>
                            </UserContentProvider>
                        </NavigateProvider>
                    </GoogleOAuthProvider>
                </AuthProvider>
            </Router>
    );
};

export default App;