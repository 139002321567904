import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Logout.css'

const Logout: React.FC = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        try {
            logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
            // Still navigate to login page even if logout fails
            navigate('/login');
        }
    };

    return (
        <button 
            className="logout-button" 
            onClick={handleLogout}
        >
            Logout
        </button>
    );
};

export default Logout;