import React from 'react';
import './ApplicationIcon.css';

const ApplicationIcon = ({ collapsed }) => {
    return (
        <div className="app-icon">
            <img src="/images/macaw_logo_v0.svg" alt="Application Logo" className="app-logo" />
            {!collapsed && <span></span>}
        </div>
    );
};

export default ApplicationIcon;