import React from 'react';
import PageHeader from '../components/PageHeader';
//import Signup from '../components/Signup';
import './Signup.css';

const SignupPage: React.FC = () => {
    return (
        <div className="signup-page">
            <PageHeader title="signup" subtitle="Sign up to get started" />
            <div className="signup-content">
            </div>
        </div>
    );
};

export default SignupPage;