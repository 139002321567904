import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UilEdit, UilArchiveAlt, UilSetting, UilUserCircle, UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons';
import ApplicationIcon from './ApplicationIcon'; // Import the application icon component
import './Sidebar.css';

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);

    const toggleSidebar = () => {
        if (!collapsed) {
            setFadeOut(true);
            setTimeout(() => {
                setCollapsed(true);
                setFadeOut(false);
            }, 100); // Match this timeout to the CSS opacity transition duration
        } else {
            setCollapsed(false);
        }
    };

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : 'expanded'} ${fadeOut ? 'fade-out' : ''}`}>
            <div className="sidebar-header">
                <ApplicationIcon collapsed={collapsed} />
            </div>
            <div className="sidebar-content">
                <ul>
                    <li>
                        <Link to="/chat">
                            <UilEdit className="fa-icon" /> <span className="link-text">{!collapsed && 'Chat'}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/archive">
                            <UilArchiveAlt className="fa-icon" /> <span className="link-text">{!collapsed && 'Archive'}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/settings">
                            <UilSetting className="fa-icon" /> <span className="link-text">{!collapsed && 'Settings'}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/account">
                            <UilUserCircle className="fa-icon" /> <span className="link-text">{!collapsed && 'Account'}</span>
                        </Link>
                    </li>
                </ul>
                <div className="toggle-btn-bottom" onClick={toggleSidebar}>
                    {collapsed ? <UilAngleLeft className="fa-icon" /> : <UilAngleRight className="fa-icon" />}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;