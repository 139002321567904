import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction, useEffect } from 'react';
import { UserContextType, User, Resume, JobDescription } from '../types/UserContext';

// Define the types for the context
interface UserContentContextType {
    userContext: UserContextType;
    setUserContext: Dispatch<SetStateAction<UserContextType>>;
}

// Create the context with a default value
const UserContentContext = createContext<UserContentContextType | undefined>(undefined);

const localStorageKey = 'userContent';

export const UserContentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const loadUserContext = (): UserContextType => {
        const savedUserContext = localStorage.getItem(localStorageKey);
        if (savedUserContext) {
            try {
                const parsedContext = JSON.parse(savedUserContext) as UserContextType;
                return {
                    resume: parsedContext.resume ? Object.assign(new Resume(), parsedContext.resume) : new Resume(),
                    jobDescription: parsedContext.jobDescription ? Object.assign(new JobDescription(), parsedContext.jobDescription) : new JobDescription(),
                    user: parsedContext.user ? Object.assign(new User(), parsedContext.user) : new User(),
                };
            } catch (error) {
                console.error("Error parsing user context from localStorage", error);
                return {
                    resume: new Resume(),
                    jobDescription: new JobDescription(),
                    user: new User()
                };
            }
        }
        return {
            resume: new Resume(),
            jobDescription: new JobDescription(),
            user: new User()
        };
    };

    const [userContext, setUserContext] = useState<UserContextType>(loadUserContext);

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(userContext));
    }, [userContext]);

    return (
        <UserContentContext.Provider value={{ userContext, setUserContext }}>
            {children}
        </UserContentContext.Provider>
    );
};

export const useUserContent = (): UserContentContextType => {
    const context = useContext(UserContentContext);
    if (!context) {
        throw new Error('useUserContent must be used within a UserContentProvider');
    }
    return context;
};