import React, { useState, useEffect, useRef } from 'react';
import PageHeader from '../components/PageHeader';
import ChatInput from '../components/ChatInput';
import ChatResponse from '../components/ChatResponse';
import './Chat.css';

interface Message {
    id: number;
    text: string;
    isUser: boolean;
}

interface ChatApiResponse {
    chat_id: string;
    response: string;
}

const Chat: React.FC = () => {
    const chatId = crypto.randomUUID();
    const [messages, setMessages] = useState<Message[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]); // Scroll whenever messages update

    const handleSendMessage = async (message: string): Promise<void> => {
        const userMessage: Message = {
            id: Date.now(),
            text: message,
            isUser: true
        };
        
        setMessages(prev => [...prev, userMessage]);
        setIsLoading(true);

        try {
            const baseUrl = process.env.REACT_APP_AGENT_BASE_URL || '';
            const response = await fetch(baseUrl + '/chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Request-ID': crypto.randomUUID(),
                },
                body: JSON.stringify({ 
                    chat_id: chatId,
                    message: message 
                }),
            });

            const data = await response.json() as ChatApiResponse;
            
            const aiResponse: Message = {
                id: Date.now() + 1,
                text: data.response,
                isUser: false
            };

            setMessages(prev => [...prev, aiResponse]);
        } catch (error) {
            const errorResponse: Message = {
                id: Date.now() + 1,
                text: 'Sorry, there was an error connecting to the server.',
                isUser: false
            };
            setMessages(prev => [...prev, errorResponse]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendMessageWrapper = (message: string) => {
        handleSendMessage(message).catch(console.error);
    };

    return (
        <div className="workspace-page">
            <PageHeader title="Workspace" subtitle="Find the perfect wine" />
            <div className="workspace-content">
                <div className="chat-messages-container">
                    {messages.map((msg) => (
                        <ChatResponse 
                            key={msg.id} 
                            response={msg.text} 
                            isUser={msg.isUser}
                        />
                    ))}
                    {isLoading && (
                        <ChatResponse 
                            key="loading" 
                            response="" 
                            isUser={false}
                            isLoading={true}
                        />
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <div className="chat-input-wrapper">
                    <ChatInput onSendMessage={handleSendMessageWrapper} />
                </div>
            </div>
        </div>
    );
};

export default Chat;
