import React from 'react';
import useDocumentTitle from './UseDocumentTitle';
import './PageHeader.css';


const PageHeader = ({ title, subtitle }) => {
    useDocumentTitle(title);

    const renderColoredTitle = () => {
        const layers = ['layer-1', 'layer-2', 'layer-3', 'layer-4', 'layer-5'];
        return layers.map((layer, index) => (
            <span className={layer} key={index}>
                WinePilot
            </span>
        ));
    };

    return (
        <div className="page-header">
            <h1 className="page-header-title">
                {renderColoredTitle()}
            </h1>
            {subtitle && <h2 className="page-header-subtitle">{subtitle}</h2>}
        </div>
    );
};

export default PageHeader;