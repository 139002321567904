import React from 'react';
import PageHeader from '../components/PageHeader';
import './Archive.css';

const Archive = () => {
    return (
        <div className="archive-page">
            <PageHeader title="Archive" subtitle="View and manage your resumes" />
            <div className="archive-content">
                <p>Archive</p>
            </div>
        </div>
    );
};

export default Archive;