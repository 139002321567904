export interface UserContextType {
    resume: Resume | null;
    jobDescription: JobDescription | null;
    user: User | null;
}

export class User {
    private _userId: number | null = null;
    private _displayName: string | null = null;

    get userId(): number | null {
        return this._userId;
    }
    set userId(value: number | null) {
        this._userId = value;
    }
    get displayName(): string | null {
        return this._displayName;
    }
    set displayName(value: string | null) {
        this._displayName = value;
    }
}

export class Resume {
    private _resumeId: number | null = null;
    private _resumeName: string | null = null;
    private _resumeContent: string | null = null;

    get resumeId(): number | null {
        return this._resumeId;
    }

    set resumeId(value: number | null) {
        this._resumeId = value;
    }

    get resumeName(): string | null {
        return this._resumeName;
    }

    set resumeName(value: string | null) {
        this._resumeName = value;
    }

    get resumeContent(): string | null {
        return this._resumeContent;
    }

    set resumeContent(value: string | null) {
        this._resumeContent = value;
    }
}

export class JobDescription {
    private _descriptionId: number | null = null;
    private _descriptionContent: string | null = null;

    get descriptionId(): number | null {
        return this._descriptionId;
    }

    set descriptionId(value: number | null) {
        this._descriptionId = value;
    }

    get descriptionContent(): string | null {
        return this._descriptionContent;
    }

    set descriptionContent(value: string | null) {
        this._descriptionContent = value;
    }
}

